export async function masonry() {
    const masonryElements = document.querySelectorAll('[data-spwk-masonry]'),
        collapsibleCards = document.querySelectorAll('[data-card-collapsible]');

    if (masonryElements.length) {
        const { initMasonry } = await import(/* webpackMode: "lazy" */ './masonry.async.js');

        initMasonry(masonryElements);

        if (collapsibleCards.length) {
            const { initCollapsibleCards } = await import(/* webpackMode: "lazy" */ './collapsible.async.js');

            initCollapsibleCards(collapsibleCards);
        }
    }
}
