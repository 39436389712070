export function progressBars() {
    const bars = document.querySelectorAll('[data-progress-bar]');

    if (bars) {
        const intersectionObserver = new IntersectionObserver((entries, observer) => {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    const value = entry.target.querySelector('[data-progress-value]');

                    if (value) {
                        value.style.translate = Number(value.dataset.progressValue) + '% 0%';
                        observer.unobserve(entry.target);
                    }
                }
            }
        });

        for (const bar of bars) {
            intersectionObserver.observe(bar);
        }
    }
}
